<template>
  <div v-if="values">
    <div class="maininfo border_fourth">
      <span v-for="(item, key, index) in values" :key="index">
        <div class="containerInfo" v-if="Boolean(item)">
          <div class="info">
            <MoreInfoItem
              :title="titles[index] + ':'"
              :text="{ text: item }"
              :important="true"
              type="exhibitor"
            />
          </div>
        </div>
      </span>
    </div>
    <div class="secundaryinfo" v-if="checkValues.length > 0">
      <MoreInfoItem
        v-for="(item, key, index) in checkValues"
        :key="index"
        :title="titles[index + 5] + ':'"
        :text="{ text: item }"
        type="exhibitor"
        class="info"
      />
    </div>
  </div>
</template>

<script>
  import MoreInfoItem from '@/components/MoreInfoItem.vue'
  export default {
    name: 'ExhibitorInfo',
    components: {
      MoreInfoItem,
    },
    props: {
      titles: {
        type: Array,
        default: [],
      },
      values: {
        type: Object,
        default: {},
      },
    },
    computed: {
      checkValues() {
        let data = {}
        let i = 0
        for (const key in this.values) {
          if (i >= 5) {
            data[key] = this.values[key]
          }
          i++
        }
        return data
      },
    },
  }
</script>

<style scoped>
  .maininfo {
    text-align: center;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .containerInfo {
    display: inline-block;
    width: 17%;
  }
  .info {
    display: inline-block;
    vertical-align: top;
    margin: 10px 0px;
    /* padding: 0 5px; */
    width: 100%;
  }
  .secundaryinfo {
    text-align: center;
    padding: 5px;
  }
  .secundaryinfo .info {
    text-align: left;
  }
  .secundaryinfo .info {
    width: 30%;
  }
  @media screen and (max-width: 700px) {
    .containerInfo {
      width: 33%;
    }
  }
  @media screen and (max-width: 650px) {
    .maininfo {
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 400px) {
    .containerInfo {
      width: 48%;
    }
  }
</style>
