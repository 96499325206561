<template>
  <div class="viewcontent">
    <div class="contleftright">
      <div class="left">
        <Title
          background="primary_bg_color"
          type="subtitle"
          :text="exhibitorData.brand"
        />

        <div class="imgcont">
          <Slider
            class="slider"
            :perPage="1"
            :images="exhibitorData.brandImg"
            :indexNum="exhibitorData.brandImg.length"
            :link="false"
            v-if="exhibitorData.brandImg && exhibitorData.brandImg.length > 1"
          />
          <img
            v-else-if="
              exhibitorData.brandImg && exhibitorData.brandImg.length == 1
            "
            :src="files + exhibitorData.brandImg[0].url"
            :alt="exhibitorData.brandImg[0].description"
            class="brandimg"
          />
        </div>

        <div v-if="exhibitorData.brandText" class="descriptioncont">
          <p
            v-for="(item, index) in exhibitorData.brandText.split(
              /<br\s*\/?>|\n/g
            )"
            :key="index"
            class="description"
          >
            {{ item }}
          </p>
        </div>
        <ExhibitorTools
          :size="50"
          :info="{
            brand: exhibitorData.brand,
            code: exhibitorData.code,
            type: 'exhibitor',
            cards: exhibitorData.cards,
          }"
          :key="exhibitorData.code"
          v-if="exhibitorData.status >= 2"
        />
      </div>
      <div class="right">
        <div
          class="awards"
          v-if="
            exhibitorData.brandAwards && exhibitorData.brandAwards.length > 0
          "
        >
          <Title
            background="secundary_bg_color"
            type="subtitle"
            :text="
              replaceTranslation({ type: 'awards', text: exhibitorData.brand })
            "
          />
          <a
            v-for="(award, index) in exhibitorData.brandAwards.slice(0, 3)"
            :href="award.url"
            :key="index"
            target="_blank"
            class="primary_color"
          >
            <img :src="award.img" :alt="award.description" class="imgaward" />
          </a>
        </div>
        <div
          class="contproduct"
          v-if="
            exhibitorData.consumerProduct &&
              exhibitorData.consumerProduct.length > 0
          "
        >
          <Title
            background="secundary_bg_color"
            type="subtitle"
            :text="translations.title_consumer_product"
          />
          <div
            v-for="(product, index) in exhibitorData.consumerProduct.slice(
              0,
              9
            )"
            :key="index"
            @click="linkTo(product.cid, 'products')"
            class="product"
          >
            <ExhibitorTool
              :tool="{
                text: product.text + ' (' + product.number + ')',
                icon: product.icon,
              }"
              :border="false"
              :svg="true"
              size="medium"
              class="productitem"
            />
          </div>
        </div>
        <div v-if="exhibitorData.video_url && checkUrl">
          <Title
            background="primary_bg_color"
            type="subtitle"
            :text="translations.title_video"
          />
          <Video :url="generateURL" class="video" />
        </div>
        <div v-if="exhibitorData.objectives">
          <Title
            background="primary_bg_color"
            type="subtitle"
            :text="translations.title_objectives"
          />
          <div
            v-for="(objective, index) in defineObjectives"
            :key="index"
            class="objectivelist"
          >
            <span class="listtitle"> {{ objective.text }}: </span>
            <!-- {{ exhibitorData.objectives }} -->
            <ul>
              <li v-for="(item, itemIndex) in objective.list" :key="itemIndex">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="contproduct"
          v-if="
            exhibitorData.businessProduct &&
              exhibitorData.businessProduct.length > 0
          "
        >
          <Title
            background="secundary_bg_color"
            type="subtitle"
            :text="translations.title_business_product"
          />
          <div
            v-for="(product, index) in exhibitorData.businessProduct.slice(
              0,
              9
            )"
            :key="index"
            @click="linkTo(product.cid, 'products')"
            class="product"
          >
            <ExhibitorTool
              :tool="{
                text: product.text + ' (' + product.number + ')',
                icon: product.icon,
              }"
              :border="false"
              :svg="true"
              size="medium"
              class="productitem"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="lookingcontent"
      v-if="
        exhibitorData.brandLookingFor &&
          exhibitorData.brandLookingFor.length > 0
      "
    >
      <Title
        background="primary_bg_color"
        type="subtitle"
        :text="
          replaceTranslation({ type: 'looking', text: exhibitorData.brand })
        "
      />
      <ExhibitorTool
        v-for="(item, index) in exhibitorData.brandLookingFor.slice(0, 5)"
        :key="index"
        :tool="{
          text: item.text,
          icon: item.icon,
          description: item.countries,
        }"
        class="lookingfor"
        :border="false"
        size="medium"
      />
    </div>

    <Title
      background="primary_bg_color"
      type="subtitle"
      :text="translations.title_company_info"
    />
    <ExhibitorInfo
      :titles="moreBrandInfoTitles"
      :values="exhibitorData.moreBrandInfo"
    />

    <div
      class="viewanddownloadcont"
      v-if="exhibitorData.downloads && exhibitorData.downloads.length > 0"
    >
      <Title
        background="primary_bg_color"
        type="subtitle"
        :text="translations.title_downloads"
      />
      <SaveAndDownload
        v-for="(item, index) in exhibitorData.downloads"
        :key="index"
        :data="item"
        :index="index"
        :type="'download'"
        :class="defineDownloadclas"
      />
    </div>
    <!-- <div class="retailcont" v-if="exhibitorData.retail">
      <ExhibitorRetail :retail="exhibitorData.retail" class="retail" />
    </div> -->

    <div
      class="brandcategories border_fourth"
      v-if="checkCategories() && checkCategories().length > 0"
    >
      <Title
        background="tertiary_bg_color"
        type="subtitle"
        :text="translations.title_otherbrands"
      />
      <Category
        v-for="(category, index) in checkCategories().slice(0, 6)"
        :key="index"
        :limitList="6"
        :category="{ list: category.list }"
        :icon="{
          text: category.title,
          icon: category.icon,
        }"
        :link="true"
        :index="index"
        :decoration="true"
        class="category"
      />
      <div v-if="checkLength(checkCategories())" class="buttoncont">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(checkCategories().length)"
        >
          {{ replaceTranslation({ type: 'showmore', text: limit.number }) }}
        </button>
      </div>
      <div v-if="!limit.more">
        <button
          class="showmore tertiary_bg_color contrast_color"
          @click="cangeLimit(6)"
        >
          {{ translations.btn_showless }}
        </button>
      </div>
    </div>

    <div
      class="otherproductcont"
      v-if="
        exhibitorData.promotedProducts &&
          exhibitorData.promotedProducts.length > 0
      "
    >
      <Title
        background="primary_bg_color"
        type="subtitle"
        :text="translations.title_popular_products"
      />
      <div
        v-for="(product, index) in exhibitorData.promotedProducts"
        :key="index"
        class="otherproductitem"
        @click="linkTo(product.code, 'product')"
      >
        <div class="otherproduct">
          <img :src="files + product.image" :alt="product.title" />
        </div>
        <p>
          {{ product.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import ExhibitorTools from '@/components/ExhibitorTools.vue'
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import ExhibitorInfo from '@/components/ExhibitorInfo.vue'
  import SaveAndDownload from '@/components/SaveAndDownload.vue'
  import ExhibitorRetail from '@/components/ExhibitorRetail.vue'
  import Category from '@/components/Category.vue'
  import Title from '@/components/Title.vue'
  import Video from '@/components/Video.vue'
  import Slider from '@/components/Slider.vue'

  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Exhibitor',
    components: {
      Title,
      ExhibitorTools,
      ExhibitorTool,
      ExhibitorInfo,
      SaveAndDownload,
      ExhibitorRetail,
      Slider,
      Category,
      Video,
    },
    data() {
      return {
        limit: { limit: 6, number: 0, more: true },
        numberList: 0,
        moreBrandInfoTitles: [],
        objectives: [],
        categoriesRelated: [],
      }
    },
    props: {
      data: {
        type: Object,
        default: {},
      },
    },
    computed: {
      ...mapState(['exhibitorData', 'allData', 'files', 'translations']),

      defineDownloadclas() {
        return 'oneviewanddownload'
      },
      checkUrl() {
        let url

        try {
          url = new URL(this.generateURL)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      generateURL() {
        return 'https://iframe.dacast.com/vod/' + this.exhibitorData.video_url
      },

      defineObjectives() {
        let result = []
        this.objectives.forEach((element) => {
          for (const key in this.exhibitorData.objectives) {
            if (
              Object.hasOwnProperty.call(this.exhibitorData.objectives, key)
            ) {
              if (element.key == key) {
                result.push({
                  text: element.text,
                  list: this.exhibitorData.objectives[key],
                })
              }
            }
          }
        })
        return result
      },
    },
    methods: {
      ...mapActions([
        'getProducts',
        'getExhibitorData',
        'getExhibitors',
        'resetExhibitor',
      ]),

      checkCategories() {
        if (this.allData.categoriesProd) {
          return this.allData.categoriesProd.filter((item) => {
            let res = ''
            if (item.list && item.list.length > 1) {
              for (const element of item.list) {
                if (element.code == this.exhibitorData.code) {
                  res = item
                  break
                }
              }
            }
            // res = item
            return res
          })
        }
      },

      async linkTo(id, type) {
        switch (type) {
          case 'products':
            await this.getProducts({
              category_id: id,
              company_code: this.exhibitorData.code,
            })
            router.push('/productlist')
            break
          case 'product':
            await router.push('/product/' + id)
            break
          case 'exhibitor':
            await this.getExhibitors({ category_id: id })
            router.push('/exhibitorlist')
            break

          default:
            break
        }
      },
      checkLength(data) {
        let result = false
        if (data.length > this.limit.limit) {
          result = true
          this.limit.number = data.length - this.limit.limit
        }
        return result
      },
      cangeLimit(num) {
        this.limit.limit = num
        this.limit.more = !this.limit.more
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'awards':
            result = this.translations.title_awards.replace('{var}', value.text)
            break
          case 'looking':
            result = this.translations.title_lookingfor.replace(
              '{var}',
              value.text
            )
            break
          case 'showmore':
            result = this.translations.btn_showmore_numeric_categories.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
      addTranslations() {
        if (this.translations) {
          this.objectives = [
            { key: 'operations', text: this.translations.type_operations },
            { key: 'targetAudiences', text: this.translations.type_target },
            {
              key: 'targetMarkets',
              text: this.translations.type_target_markets,
            },
          ]
          this.moreBrandInfoTitles = [
            this.translations.label_info_company,
            this.translations.label_info_registered,
            this.translations.label_info_established,
            this.translations.label_info_business_size,
            this.translations.label_info_product_categories,
            this.translations.label_info_certificates,
            this.translations.label_info_customer,
            this.translations.label_info_website,
          ]
        }
      },
    },

    async created() {
      await this.getExhibitorData(this.$route.params.id)
      this.addTranslations()
    },
    watch: {
      $route(to, from) {
        if (to.name == 'Exhibitor') {
          this.getExhibitorData(this.$route.params.id)
          this.limit = { limit: 6, number: 0, more: true }
        }
      },
    },
    beforeUnmount() {
      this.resetExhibitor()
    },
  }
</script>

<style scoped>
  .contleftright {
    text-align: center;
  }
  .left,
  .right {
    width: 48%;
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .description {
    margin: 1rem 0;
    text-align: left;
    word-wrap: break-word;
    width: auto;
  }
  .brandimg {
    max-width: 100%;
    height: auto;
  }
  .imgaward {
    width: 31%;
    padding: 5px;
  }
  .video {
    height: 250px;
  }
  .contproduct,
  .companiescategories,
  .brandcategories,
  .awards {
    text-align: center;
  }
  .product {
    width: 32%;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
  }

  .lookingcontent {
    text-align: center;
  }
  .lookingfor {
    display: inline-block;
    width: 18%;
    vertical-align: top;
  }

  /* .tools {
    margin-top: 30px;
  } */
  .companiescategories {
    border-top-width: 1px;
    border-top-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .companiescategories,
  .brandcategories {
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .oneviewanddownload {
    width: 100%;
    padding: 0;
  }
  .companiescategories .category {
    height: 450px;
    margin: 20px 0px;
  }
  .brandcategories .category,
  .companiescategories .category {
    width: 32%;
    vertical-align: top;
    display: inline-block;
  }
  .brandcategories {
    margin-top: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .brandcategories .category {
    height: 250px;
  }
  .imgcont {
    position: relative;
    height: 355px;
  }
  .imgcont img {
    height: 100%;
  }

  .otherproduct {
    position: relative;
    height: 150px;
  }
  .otherproductitem p {
    text-align: center;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; /* number of lines to show */
  }

  .otherproduct img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .otherproductcont {
    text-align: center;
  }

  .otherproductitem {
    display: inline-block;
    width: 33%;
    vertical-align: top;
    margin: 10px 0;
  }
  .listtitle {
    font-weight: bold;
  }
  .objectivelist {
    text-align: left;
    padding: 0 20px;
  }

  @media screen and (max-width: 950px) {
    .viewanddownload {
      width: 100%;
    }
    .descriptioncont {
      padding: 10px;
    }
  }
  @media screen and (max-width: 900px) {
    .right,
    .left {
      width: 100%;
      padding: 0;
    }
  }
  @media screen and (max-width: 800px) {
    .brandcategories .category,
    .companiescategories .category {
      width: 49%;
    }
    .companiescategories .category {
      margin: 0;
    }
  }
  @media screen and (max-width: 550px) {
    /* .right,
    .left {
      width: 100%;
    } */
    .otherproductitem {
      width: 50%;
    }
  }

  @media screen and (max-width: 500px) {
    .imgaward {
      width: 80%;
    }
    .product,
    .lookingfor {
      width: 40%;
    }
    .brandcategories .category,
    .companiescategories .category {
      width: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    .imgaward {
      width: 90%;
    }
  }
</style>
